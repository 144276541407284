@font-face {
  font-family: qvbold;
  src: url(../Assests/Fonts/circular-bold.TTF);
}
@font-face {
  font-family: qvmedium;
  src: url(../Assests/Fonts/circulur-medium.TTF);
}
@font-face {
  font-family: qvblack;
  src: url(../Assests/Fonts/circulur-std-black.TTF);
}
@font-face {
  font-family: qvbook;
  src: url(../Assests/Fonts/circulur-std-book.TTF);
}
@font-face {
  font-family: qvstdmedium;
  src: url(../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf);
}
.box {
  width: 92%;
  height: auto;
  margin: 0 auto;
}
.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.box_shadow_div_order {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
  padding-bottom: 30px;
}

.box_shadow_div_order label {
  color: #818181;
  font-size: 14px;
  font-family: CircularMedium !important;
  display: block;
  margin: 0px;
}

.heading {
  color: #000000;
  font-family: adminmedium;
  font-size: 18px;
  margin-bottom: 8px;
}
.pd_20 {
  padding: 20px;
}

.input_area input {
  font-family: qvmedium !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 0 0;
  width: 100%;
  /* height: 40px; */
}
.input_area textarea {
  font-family: qvmedium !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 15px 0;
  width: 100%;
}
.input_area select {
  font-family: qvmedium !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 15px 0;
  width: 100%;
  height: 40px;
}
.box_shadow_heading {
  color: #000000;
  font-size: 18px;
  font-family: qvstdmedium !important;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  height: auto;
  display: inline-block;
}
.qv_checkbox input:checked ~ .qv_add_checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}
.qv_add_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.qv_checkbox input:checked ~ .qv_add_checkmark:after {
  display: block;
}
.qv_checkbox .qv_add_checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.input_area fieldset {
  display: none;
}
.input_area .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 8px;
}
.button_area {
  display: flex;
  align-items: center;
}
.inputredio[type="radio"]:checked,
.inputredio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.inputredio[type="radio"]:checked + label,
.inputredio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.inputredio[type="radio"]:checked + label {
  color: #0a64f9;
}
.inputredio[type="radio"]:checked + label:before,
.inputredio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.inputredio[type="radio"]:checked + label:before {
  border: 1px solid #0a64f9;
}
.inputredio[type="radio"]:checked + label:after,
.inputredio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #0a64f9;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.inputredio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inputredio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.box_shadow_div_heading {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
  padding-bottom: 30px;
}

.box_shadow_div_order label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  display: block;
  margin: 0px;
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.qv_checkbox .cursor-disabled {
  opacity: 0.5 !important;
  cursor: no-drop !important;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-qv-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-qv-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-qv-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-qv-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.col-qv-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-qv-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-qv-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-qv-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-qv-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-qv-12 {
  flex: 0 0 auto;
  width: 100%;
}
.error {
  color: red !important;
  font-size: 12px !important;
}
.box_shadow_div {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
  padding: 0px;
  overflow: hidden;
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  height: auto;
  display: inline-block;
}
.qv_checkbox input:checked ~ .qv_add_checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}
.qv_add_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.qv_checkbox input:checked ~ .qv_add_checkmark:after {
  display: block;
}
.qv_checkbox .qv_add_checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.save_btn {
  background: #0a64f9;
  font-family: CircularSTDBook !important;
  font-size: 16px;
  color: #fff;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}
.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.input_area fieldset {
  display: none;
}
.input_area .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 8px;
}
.button_area {
  display: flex;
  align-items: center;
}
.inputredio[type="radio"]:checked,
.inputredio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.inputredio[type="radio"]:checked + label,
.inputredio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.inputredio[type="radio"]:checked + label {
  color: #0a64f9;
}
.inputredio[type="radio"]:checked + label:before,
.inputredio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.inputredio[type="radio"]:checked + label:before {
  border: 1px solid #0a64f9;
}
.inputredio[type="radio"]:checked + label:after,
.inputredio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #0a64f9;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.inputredio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inputredio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.box_shadow_div_heading {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
}
.page_heading_area {
  width: 100%;
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.page_heading_area h1 {
  font-size: 18px;
  font-family: adminmedium !important;
}
.box_shadow_innerdiv {
  padding: 20px;
}
.heading_black {
  font-size: 18px;
  font-family: adminbold !important ;
}
.input_rediobutton_area {
  width: auto;
  display: inline-block;
  height: auto;
  margin-right: 20px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.btn-area {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
.blue_btn {
  font-size: 16px;
  background: #0a64f9;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.blue_btn:hover,
.black_btn:hover,
.grey_btn:hover {
  opacity: 0.8;
}
input.blue_btn {
  margin: 0px;
  background: #0a64f9;
  width: auto;
  padding: 8px 25px;
  color: #fff;
}
.black_btn {
  font-size: 16px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.grey_btn {
  font-size: 16px;
  background: #878787;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
  margin-left: 10px;
}
.btnsamewidth {
  width: 120px;
}
.text_right {
  text-align: right;
}
.table_main_area {
  width: 100%;
  height: auto;
}
.table_header {
  background: #253338;
  display: flex;
  padding: 10px 20px;
}
.table_header p {
  font-size: 14px;
  color: #fff;
  font-family: qvbook;
}
.table_body {
  width: 100%;
  height: auto;
  display: inline-block;
}
.table_header_sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 8px;
  background: #fff;
}
.table_row {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.table_row p {
  color: #000;
  font-size: 14px;
  font-family: qvmedium;
}
.txt_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.table5 {
  width: 5%;
}
.table8 {
  width: 8%;
}
.table10 {
  width: 10%;
}
.table12 {
  width: 12%;
}
.table15 {
  width: 15%;
}

.table18 {
  width: 18%;
}
.table19 {
  width: 19%;
}
.table20 {
  width: 20%;
}
.table25 {
  width: 25%;
}
.table30 {
  width: 30%;
}
.table35 {
  width: 35%;
}
.table40 {
  width: 40%;
}
.table45 {
  width: 45%;
}
.table50 {
  width: 50%;
}
.table55 {
  width: 55%;
}
.table60 {
  width: 60%;
}
.table65 {
  width: 65%;
}
.table70 {
  width: 70%;
}
.table75 {
  width: 75%;
}
.table80 {
  width: 80%;
}
.table85 {
  width: 85%;
}
.table90 {
  width: 90%;
}
.table_header_top {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}
.table_header_top h1 {
  color: #000000;
  font-family: adminmedium;
  font-size: 18px;
}
.icon_img {
  width: auto;
  height: auto;
  cursor: pointer;
}
.verifiedTableIcon {
  display: flex;
  justify-content: space-around;
}
.seacrh_area {
  width: 100%;
  padding: 12px 20px;
}
.seacrh_area .input_area input {
  margin: 0;
}
.viewMerchant:hover {
  color: #0a64f9;
  cursor: pointer;
}
.merchant-disabled {
  background: #f5f5f5 !important;
  cursor: not-allowed;
  color: red;
}

@font-face {
  font-family: qvbold;
  src: url(../Assests/Fonts/circular-bold.TTF);
}
@font-face {
  font-family: qvmedium;
  src: url(../Assests/Fonts/circulur-medium.TTF);
}
@font-face {
  font-family: qvblack;
  src: url(../Assests/Fonts/circulur-std-black.TTF);
}
@font-face {
  font-family: qvbook;
  src: url(../Assests/Fonts/circulur-std-book.TTF);
}
@font-face {
  font-family: qvstdmedium;
  src: url(../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf);
}

.box {
  width: 92%;
  height: auto;
  margin: 0 auto;
}
.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-qv-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-qv-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-qv-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-qv-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.col-qv-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-qv-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-qv-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-qv-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-qv-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-qv-12 {
  flex: 0 0 auto;
  width: 100%;
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  height: auto;
  display: inline-block;
}
.qv_checkbox input:checked ~ .qv_add_checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}
.qv_add_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.qv_checkbox input:checked ~ .qv_add_checkmark:after {
  display: block;
}
.qv_checkbox .qv_add_checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.save_btn {
  background: #0a64f9;
  font-family: CircularSTDBook !important;
  font-size: 16px;
  color: #fff;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}
.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.input_area fieldset {
  display: none;
}
.input_area .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 8px;
}
.button_area {
  display: flex;
  align-items: center;
}
.inputredio[type="radio"]:checked,
.inputredio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.inputredio[type="radio"]:checked + label,
.inputredio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.inputredio[type="radio"]:checked + label {
  color: #0a64f9;
}
.inputredio[type="radio"]:checked + label:before,
.inputredio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.inputredio[type="radio"]:checked + label:before {
  border: 1px solid #0a64f9;
}
.inputredio[type="radio"]:checked + label:after,
.inputredio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #0a64f9;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.inputredio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inputredio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.box_shadow_div_heading {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
}
.page_heading_area {
  width: 100%;
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.page_heading_area h1 {
  font-size: 18px;
  font-family: adminmedium !important;
}
.box_shadow_innerdiv {
  padding: 20px;
}
.heading_black {
  font-size: 18px;
  font-family: adminbold !important ;
}
.input_rediobutton_area {
  width: auto;
  display: inline-block;
  height: auto;
  margin-right: 20px;
}
.mt10 {
  margin-top: 10px;
}
.btn-area {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
.blue_btn {
  font-size: 16px;
  background: #0a64f9;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.blue_btn:hover,
.black_btn:hover,
.grey_btn:hover {
  opacity: 0.8;
}
input.blue_btn {
  margin: 0px;
  background: #0a64f9;
  width: auto;
  padding: 8px 25px;
  color: #fff;
}
.black_btn {
  font-size: 16px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.grey_btn {
  font-size: 16px;
  background: #878787;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
  margin-left: 10px;
}
.btnsamewidth {
  width: 120px;
}
.text_right {
  text-align: right;
}
.table_main_area {
  width: 100%;
  height: auto;
}
.table_header {
  background: #253338;
  display: flex;
  padding: 10px 20px;
}
.table_header p {
  font-size: 14px;
  color: #fff;
  font-family: qvbook;
}
.table_body {
  width: 100%;
  height: auto;
  display: inline-block;
}
.table_header_sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 8px;
  background: #fff;
}
.table_row {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.table_row p {
  color: #000;
  font-size: 14px;
  font-family: qvmedium;
}
.txt_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.table5 {
  width: 5%;
}
.table8 {
  width: 8%;
}
.table10 {
  width: 10%;
}
.table12 {
  width: 12%;
}
.table15 {
  width: 15%;
}

.table18 {
  width: 18%;
}
.table19 {
  width: 19%;
}
.table20 {
  width: 20%;
}
.table25 {
  width: 25%;
}
.table30 {
  width: 30%;
}
.table35 {
  width: 35%;
}
.table40 {
  width: 40%;
}
.table45 {
  width: 45%;
}
.table50 {
  width: 50%;
}
.table55 {
  width: 55%;
}
.table60 {
  width: 60%;
}
.table65 {
  width: 65%;
}
.table70 {
  width: 70%;
}
.table75 {
  width: 75%;
}
.table80 {
  width: 80%;
}
.table85 {
  width: 85%;
}
.table90 {
  width: 90%;
}
.table_header_top {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}
.table_header_top h1 {
  color: #000000;
  font-family: adminmedium;
  font-size: 18px;
}
.icon_img {
  width: auto;
  height: auto;
  cursor: pointer;
}

/* .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;

  padding: 20px 0 !important;
} */

.css-wsp0cs-MultiValueGeneric {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 90% !important;
  padding: 4px !important;
  /* padding-left: 6px; */
  box-sizing: border-box;
  background-color: lightgray !important;
  margin: 2px !important;
}

.q_dashboard_welcom_msg > h1 {
  font-size: 24px;
  /* font-family: qvmedium !important; */
}
.q_dashbaord_netsales > h1 {
  font-size: 20px;
  /* font-family: qvmedium !important; */
}

/* select {
  margin-top: -1rem;
  border: 2px solid #0a64f9 !important;
  padding: 10px 30px;
  border-radius: 4px;
  background-color: white;
}
select:active {
  margin-top: -1rem;
  border: 2px solid #0a64f9 !important;
  padding: 10px 30px;
  border-radius: 4px;
  background-color: white;
} */

.pagination_selected {
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb !important;
}

.pagination_selected:active {
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb !important;
}

@font-face {
  font-family: qvbold;
  src: url(../Assests/Fonts/circular-bold.TTF);
}
@font-face {
  font-family: qvmedium;
  src: url(../Assests/Fonts/circulur-medium.TTF);
}
@font-face {
  font-family: qvblack;
  src: url(../Assests/Fonts/circulur-std-black.TTF);
}
@font-face {
  font-family: qvbook;
  src: url(../Assests/Fonts/circulur-std-book.TTF);
}
@font-face {
  font-family: qvstdmedium;
  src: url(../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf);
}
.box {
  width: 92%;
  height: auto;
  margin: 0 auto;
  position: relative;
}
.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-qv-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-qv-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-qv-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-qv-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.col-qv-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-qv-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-qv-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-qv-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-qv-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-qv-12 {
  flex: 0 0 auto;
  width: 100%;
}
.error {
  color: red !important;
  font-size: 12px !important;
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  height: auto;
  display: inline-block;
}
.qv_checkbox input:checked ~ .qv_add_checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}
.qv_add_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.qv_checkbox input:checked ~ .qv_add_checkmark:after {
  display: block;
}
.qv_checkbox .qv_add_checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.save_btn {
  background: #0a64f9;
  font-family: CircularSTDBook !important;
  font-size: 16px;
  color: #fff;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}
.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.input_area fieldset {
  display: none;
}
.input_area .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 8px;
}
.button_area {
  display: flex;
  align-items: center;
}
.inputredio[type="radio"]:checked,
.inputredio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.inputredio[type="radio"]:checked + label,
.inputredio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.inputredio[type="radio"]:checked + label {
  color: #0a64f9;
}
.inputredio[type="radio"]:checked + label:before,
.inputredio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.inputredio[type="radio"]:checked + label:before {
  border: 1px solid #0a64f9;
}
.inputredio[type="radio"]:checked + label:after,
.inputredio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #0a64f9;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.inputredio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inputredio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.box_shadow_div_heading {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
}
.page_heading_area {
  width: 100%;
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.page_heading_area h1 {
  font-size: 18px;
  font-family: adminmedium !important;
}
.box_shadow_innerdiv {
  padding: 20px;
}
.heading_black {
  font-size: 18px;
  font-family: adminbold !important ;
}
.input_rediobutton_area {
  width: auto;
  display: inline-block;
  height: auto;
  margin-right: 20px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.btn-area {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
.blue_btn {
  font-size: 16px;
  background: #0a64f9;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.blue_btn:hover,
.black_btn:hover,
.grey_btn:hover {
  opacity: 0.8;
}
input.blue_btn {
  margin: 0px;
  background: #0a64f9;
  width: auto;
  padding: 8px 25px;
  color: #fff;
}
.black_btn {
  font-size: 16px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.grey_btn {
  font-size: 16px;
  background: #878787;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
  margin-left: 10px;
}
.btnsamewidth {
  width: 120px;
}
.text_right {
  text-align: right;
}
.table_main_area {
  width: 100%;
  height: auto;
}
.table_header {
  background: #253338;
  display: flex;
  padding: 10px 20px;
}
.table_header p {
  font-size: 14px;
  color: #fff;
  font-family: qvbook;
}
.table_body {
  width: 100%;
  height: auto;
  display: inline-block;
}
.table_header_sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 8px;
  background: #fff;
}
.table_row {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.table_row p {
  color: #000;
  font-size: 14px;
  font-family: qvmedium;
}
.txt_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.table5 {
  width: 5%;
}
.table8 {
  width: 8%;
}
.table10 {
  width: 10%;
}
.table12 {
  width: 12%;
}
.table15 {
  width: 15%;
}

.table18 {
  width: 18%;
}
.table19 {
  width: 19%;
}
.table20 {
  width: 20%;
}
.table25 {
  width: 25%;
}
.table30 {
  width: 30%;
}
.table35 {
  width: 35%;
}
.table40 {
  width: 40%;
}
.table45 {
  width: 45%;
}
.table50 {
  width: 50%;
}
.table55 {
  width: 55%;
}
.table60 {
  width: 60%;
}
.table65 {
  width: 65%;
}
.table70 {
  width: 70%;
}
.table75 {
  width: 75%;
}
.table80 {
  width: 80%;
}
.table85 {
  width: 85%;
}
.table90 {
  width: 90%;
}
.table_header_top {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}
.table_header_top h1 {
  color: #000000;
  font-family: adminmedium;
  font-size: 18px;
}
.icon_img {
  width: auto;
  height: auto;
  cursor: pointer;
}
.verifiedTableIcon {
  display: flex;
  justify-content: space-around;
}

@font-face {
  font-family: qvbold;
  src: url(../Assests/Fonts/circular-bold.TTF);
}
@font-face {
  font-family: qvmedium;
  src: url(../Assests/Fonts/circulur-medium.TTF);
}
@font-face {
  font-family: qvblack;
  src: url(../Assests/Fonts/circulur-std-black.TTF);
}
@font-face {
  font-family: qvbook;
  src: url(../Assests/Fonts/circulur-std-book.TTF);
}
@font-face {
  font-family: qvstdmedium;
  src: url(../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf);
}

.box {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.pd_20 {
  padding: 20px;
}

.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-qv-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-qv-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-qv-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-qv-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.col-qv-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-qv-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-qv-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-qv-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-qv-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-qv-12 {
  flex: 0 0 auto;
  width: 100%;
}

.pd_20 {
  padding: 20px;
}

.box_shadow_div label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  display: block;
  margin: 0px;
}
.input_area {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #000;
  font-size: 16px;
  font-family: adminmedium !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox_space {
  width: 100%;
  height: auto;
  display: inline-block;
}
.qv_checkbox input:checked ~ .qv_add_checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}
.qv_add_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.qv_checkbox input:checked ~ .qv_add_checkmark:after {
  display: block;
}
.qv_checkbox .qv_add_checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.save_btn {
  background: #0a64f9;
  font-family: CircularSTDBook !important;
  font-size: 16px;
  color: #fff;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}
.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.input_area fieldset {
  display: none;
}
.input_area .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 8px;
}
.button_area {
  display: flex;
  align-items: center;
}
.inputredio[type="radio"]:checked,
.inputredio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.inputredio[type="radio"]:checked + label,
.inputredio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.inputredio[type="radio"]:checked + label {
  color: #0a64f9;
}
.inputredio[type="radio"]:checked + label:before,
.inputredio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.inputredio[type="radio"]:checked + label:before {
  border: 1px solid #0a64f9;
}
.inputredio[type="radio"]:checked + label:after,
.inputredio[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #0a64f9;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.inputredio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inputredio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.box_shadow_div_heading {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
}
.page_heading_area {
  width: 100%;
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.page_heading_area h1 {
  font-size: 18px;
  font-family: adminmedium !important;
}
.box_shadow_innerdiv {
  padding: 20px;
}
.heading_black {
  font-size: 18px;
  font-family: adminbold !important ;
}
.input_rediobutton_area {
  width: auto;
  display: inline-block;
  height: auto;
  margin-right: 20px;
}
.mt10 {
  margin-top: 10px;
}
.btn-area {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
.blue_btn {
  font-size: 16px;
  background: #0a64f9;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.blue_btn:hover,
.black_btn:hover,
.grey_btn:hover {
  opacity: 0.8;
}
input.blue_btn {
  margin: 0px;
  background: #0a64f9;
  width: auto;
  padding: 8px 25px;
  color: #fff;
}
.black_btn {
  font-size: 16px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
}
.grey_btn {
  font-size: 16px;
  background: #878787;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  width: auto;
  cursor: pointer;
  font-family: qvbook;
  margin-left: 10px;
}
.btnsamewidth {
  width: 120px;
}
.text_right {
  text-align: right;
}
.table_main_area {
  width: 100%;
  height: auto;
}
.table_header {
  background: #253338;
  display: flex;
  padding: 10px 20px;
}
.table_header p {
  font-size: 14px;
  color: #fff;
  font-family: qvbook;
}
.table_body {
  width: 100%;
  height: auto;
  display: inline-block;
}
.table_header_sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 8px;
  background: #fff;
}
.table_row {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.table_row p {
  color: #000;
  font-size: 14px;
  font-family: qvmedium;
}
.txt_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.table5 {
  width: 5%;
}
.table8 {
  width: 8%;
}
.table10 {
  width: 10%;
}
.table12 {
  width: 12%;
}
.table15 {
  width: 15%;
}

.table18 {
  width: 18%;
}
.table19 {
  width: 19%;
}
.table20 {
  width: 20%;
}
.table25 {
  width: 25%;
}
.table30 {
  width: 30%;
}
.table35 {
  width: 35%;
}
.table40 {
  width: 40%;
}
.table45 {
  width: 45%;
}
.table50 {
  width: 50%;
}
.table55 {
  width: 55%;
}
.table60 {
  width: 60%;
}
.table65 {
  width: 65%;
}
.table70 {
  width: 70%;
}
.table75 {
  width: 75%;
}
.table80 {
  width: 80%;
}
.table85 {
  width: 85%;
}
.table90 {
  width: 90%;
}
.table_header_top {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}
.table_header_top h1 {
  color: #000000;
  font-family: adminmedium;
  font-size: 18px;
}
.icon_img {
  width: auto;
  height: auto;
  cursor: pointer;
}

/* .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;

  padding: 20px 0 !important;
} */

.css-wsp0cs-MultiValueGeneric {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 90% !important;
  padding: 4px !important;
  /* padding-left: 6px; */
  box-sizing: border-box;
  background-color: lightgray !important;
  margin: 2px !important;
}

.q_dashboard_welcom_msg > h1 {
  font-size: 24px;
  /* font-family: qvmedium !important; */
}
.q_dashbaord_netsales > h1 {
  font-size: 20px;
  /* font-family: qvmedium !important; */
}

select {
  margin-top: -1rem;
  border: 2px solid #0a64f9 !important;
  padding: 10px 30px;
  border-radius: 4px;
  background-color: white;
}
select:active {
  margin-top: -1rem;
  border: 2px solid #0a64f9 !important;
  padding: 10px 30px;
  border-radius: 4px;
  background-color: white;
}

.csv_btn {
  background: #fff;
  font-family: CircularSTDBook !important;
  font-size: 18px;
  color: #0a64f9;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #0a64f9;
}

.drop_down {
  display: flex;
  justify-content: space-between;
  width: 131px;
  padding: 10px;
  border: 1px solid gray;
}

/* .css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: auto !important;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid #818181 !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  min-height: 0px !important;
} */

.q_add_variant_attributes {
  font-family: CircularSTDBook !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 2px solid #0a64f9;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0 15px 0;
}

.q-add-categories-single-input {
  display: flex;
  flex-direction: column;
}

.varient-input-field:disabled {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
}
.varient-input-field:disabled:hover {
  opacity: 0.7;
}

.q_cate_details_product {
  display: flex;
  flex-wrap: wrap;
}

.q_cate_border_design {
  font-family: CircularSTDBook !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0 15px 0;
}

.q_search_icon_product {
  display: flex;
  overflow: hidden;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 16px;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  color: black !important;
}

.q_border_product {
  border: 2px solid #bfbfbf;
  border-style: dashed;
  padding: 14px;
  margin: 10px 0px;
  height: auto;
  width: 100%;
}

.q_proudct_setting_section label {
  color: #000;
}

.variant-attributes-container {
  position: relative;
  /* min-height: 50vh;  */
}

/* .q-add-categories-section-middle-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff; 
    padding: 20px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .q-category-bottom-header {
    display: flex;
    justify-content: flex-end;
  }
   */
/* .quic-btn .{
    margin-left: 10px; 
  }  */

.q_product_modal {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin-left: 23px;
}

.css-1rv8zyt-MuiStack-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  padding-top: 2px !important;
}

.q_store_working_section {
  margin: 0.8rem;
}

/* react-toastify close-btn */
.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-90%, -50%);
}

/* ============== */
/* .infoheader{
  width: 95%;
  margin: 5px auto;
  padding: 10px 5px;
}
.infoheader-left{
  float: right;
}

.info-unorder {
  list-style-type: unset;
}
.infoInput{
    width: 70% !important;
    float: left;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    margin-top: 0px !important;
}
.info-span{
    display: inline-block;
    width: 30% !important;
    background-color: #cccccc;
    text-align: center;
    line-height: 40px;
    border-radius: 0px 8px 8px 0px;
}
.info-banner{
width: 100%;
background: #f2f6ff;
height: 30vh;
border-radius: 8px 8px 8px 8px;
position: relative;
padding: 15px 0px;
}
.info-banner-image-div{
  width: 95%;
  margin: 0 auto;
  height: auto;
}

.info-file-upload{
  position: relative;
  display: inline-block;
  width: 20%;
}
.file-input1{
  width: 100%;
  height: 25vh;
  position: relative;
  background: #ffffff no-repeat padding-box;
  box-shadow: 0px 3px 22px #00000017;
  border-radius: 8px;
  opacity: 1;
}
.info-image-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.info-upload-image-button{
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #ffffff no-repeat ;
  box-shadow: 0px 3px 22px #00000017;
  border-radius: 2px;
  opacity: 1;
}
.info-upload-image-button label{
  padding: 5px 15px;
}
.info-image{
  width: 100%;
  height: 25vh;
  background-size: cover;
  border-radius: 4px;

}
.info-delete{
  position: absolute;
  z-index: 1;
  left: 18%;
  color:#ff0000;
}
.info-delete-banner{
  position: absolute;
  right: 10px;
  top: 10px;
} */

.q-catereport-quantity,
.attriButes-title {
  display: flex !important;
  cursor: pointer;
}
.q-catereport-quantity span,
.attriButes-title span {
  padding-left: 0.75rem;
}

.timesheet {
  padding: 0px !important;
  text-align: center;
  background: #f9f9f900;
}
.borderRight {
  border-right: 1px solid #000000;
  padding: 0rem 0.5rem;
}
.viewbreak {
  color: #6a6a6a;
}
.viewTextBark {
  color: #000000;
  font-weight: 500;
}
.viewTaleBreak {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
}
.viewTaleBreak p {
  padding: 4px;
  font-size: 16px;
}
.viewTableRow {
  border-bottom: 1px solid #e8e8e8;
}

.viewTimesheet-AddBreak {
  justify-content: end !important;
  display: inline-block;
  float: right;
}

.taxesApplyCategory {
  flex-direction: row !important;
}

.delete-Box-modal:focus-visible {
  outline: unset;
}
.delete-Box-modal {
  /* transform: translate(50%, 50%) !important; */
  /* transform: translateY(-45%)  !important;
  -webkit-transform: translateY(-45%)  !important;
  -ms-transform: translateY(-45%)  !important;
  -moz-transform: translateY(-45%)  !important;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
}

.delete-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 25px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}
.delete-modal .delete-modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.delete-modal .delete-modal-content img {
  height: auto;
  margin-bottom: 15px;
  width: 80px;
  border-radius: 50%;
}
.delete-modal .delete-modal-content span {
  color: #212121;
  font-family: QuicksandBold, Quicksand !important;
  font-size: 18px;
  padding: 0 10px;
  text-align: center;
}
.delete-modal .delete-modal-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
}
.delete-modal .delete-modal-button button {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: QuicksandBold, Quicksand !important;
  font-size: 15px;
  outline: none;
  padding: 10px;
  width: 48%;
}
.delete-modal .delete-modal-button button:first-child {
  border: 1px solid #232323;
}
.z-index-2 {
  z-index: 2;
}
.z-index-1 {
  z-index: 1;
}

/* .issued-date.default-border-color fieldset,
.stock-due-date.default-border-color fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;

} */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline:focus {
  border-color: 2px solid black !important;
}

.Searchmarchant {
  position: sticky !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #fff;
  background-color: #fff !important;
}

.q-attributes-bottom-header span.single-box-type,
.q-attributes-bottom-header span.single-box-type.btn {
  padding: 0px 24px !important;
  font-family: CircularSTDBook !important;
  font-size: 16px;
  border: 2px solid #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
}

.q-attributes-bottom-header span.single-box-type.btn {
  padding: 0px 14px !important;
}

.q-attributes-bottom-header span.single-box-type.active {
  color: #0a64f9;
  border: 2px solid #0a64f9;
}


.box_shadow_div_headings{
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  background-color: #fff;
}