/* .q-attributes-main-page{
    background-color: none !important;
    padding-top: 20px;
} */
.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.qvrow > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-qv-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-qv-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-qv-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-qv-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-qv-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-qv-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-qv-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-qv-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-qv-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-qv-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-qv-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-qv-12 {
  flex: 0 0 auto;
  width: 100%;
}
/* .box_shadow_div {
  width: 100%;
  height: auto;
  box-shadow: 0 5px 10px 1px #ddd;
  margin: 30px 0px;
  border-radius: 8px;
  padding: 20px;
   background-color: #fff;
} */


.box_shadow_div label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  display: block;
}
.input_area {
  width: 100%;
  height: auto;
  /* margin-bottom: 10px; */
}
.input_area input {
  font-family: CircularSTDBook !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 0 0;
  width: 100%;
  /* height: 40px; */
}
.input_area textarea {
  font-family: CircularSTDBook !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 15px 0;
  width: 100%;
}
.input_area select {
  font-family: CircularSTDBook !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 15px 0;
  width: 100%;
  height: 40px;
}
.box_shadow_heading {
  color: #000000;
  font-size: 18px;
  font-family: CircularSTDMedium !important;
  margin-bottom: 10px;
}

.qv_checkbox {
  width: auto;
  height: auto;
  float: left;
  display: inline-block;
  margin-right: 24px;
  margin-top: 10px;
}

.qv_checkbox .qv_checkbox_add_checkmark_label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.qv_checkbox .qv_checkbox_add_checkmark_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.qv_add_checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.qv_checkbox input:checked ~ .qv_add_checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.qv_add_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.qv_checkbox input:checked ~ .qv_add_checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.qv_checkbox .qv_add_checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.save_btn {
  background: #0a64f9;
  font-family: CircularSTDBook !important;
  font-size: 16px;
  color: #fff;
  padding: 7px 40px;
  border-radius: 4px;
  cursor: pointer;
  width: 160px;
  height: 40px;
}
.fr {
  float: right;
}
.input_area .css-4jnixx-MuiStack-root {
  padding-top: 0px;
}
.input_area fieldset {
  display: none;
}
.input_area .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 8px;
}

.qvrow.chkpp {
  margin-bottom: 20px;
}
