/* src/styles/tailwind.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.icon {
  color: blue;
  font-size: 40px;
}

@font-face {
  font-family: adminbold;
  src: url(./Assests/Fonts/circular-bold.TTF);
}

@font-face {
  font-family: adminmedium;
  src: url(./Assests/Fonts/circulur-medium.TTF);
}

@font-face {
  font-family: adminblack;
  src: url(./Assests/Fonts/circulur-std-black.TTF);
}

@font-face {
  font-family: adminstdbook;
  src: url(./Assests/Fonts/circulur-std-book.TTF);
}

@font-face {
  font-family: admin_std_medium;
  src: url(./Assests//Fonts/FontsFree-Net-Circular-Std-Medium.ttf);
}

@font-face {
  font-family: CircularBold;
  src: url("./Assests/Fonts/circular-bold.TTF");
}

@font-face {
  font-family: CircularMedium;
  src: url("./Assests/Fonts/circulur-medium.TTF");
}

@font-face {
  font-family: CircularSTDBlack;
  src: url("./Assests/Fonts/circulur-std-black.TTF");
}

@font-face {
  font-family: CircularSTDBook;
  src: url("./Assests/Fonts/circulur-std-book.TTF");
}

@font-face {
  font-family: CircularSTDMedium;
  src: url("./Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf");
}
@font-face {
  font-family: CircularRegular;
  src: url("./Assests/Fonts/Circular-Std-Regular-Book.ttf");
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.CircularSTDMedium-18px {
  font-family: CircularSTDMedium !important;
  font-size: 18px !important;
}
.CircularSTDMedium-15px {
  font-family: CircularSTDMedium !important;
  font-size: 15px !important;
}
.CircularSTDMedium-32px {
  font-family: CircularSTDMedium !important;
  font-size: clamp(1rem, 5vw, 32px) !important;
}
.CircularSTDBook-15px {
  font-family: CircularSTDBook !important;
  font-size: 15px !important;
}

.CircularSTDBook-14px {
  font-family: CircularSTDBook !important;
  font-size: 14px !important;
}
.CircularRegular-15px {
  font-family: CircularRegular !important;
  font-size: 15px !important;
}

img {
  max-width: unset !important;
}
h3 {
  font-family: "CircularSTDMedium";
}

.CircularSTDMedium-16px {
  font-family: "CircularSTDMedium";
  font-size: 16px;
}
.CircularSTDBook-15px {
  font-family: CircularSTDBook;
  font-size: 15px;
}
.CircularSTDBook-13px {
  font-family: CircularSTDBook !important;
  font-size: 13px !important;
}

.CircularSTDBook-12px {
  font-family: CircularSTDBook !important;
  font-size: 12px !important;
}

.CircularBold-13px {
  font-family: CircularBold !important;
  font-size: 13px !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.main-page-home-dashboard {
  width: 100%;
  height: auto;
  display: inline-block;
}

.q_header_section {
  position: sticky;
  top: 0;
  z-index: 999;
}

.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.qvrow > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col-qv-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-qv-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-qv-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-qv-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-qv-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-qv-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-qv-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-qv-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-qv-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-qv-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-qv-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-qv-12 {
  flex: 0 0 auto;
  width: 100%;
}

.Admin_bold {
  font-family: adminbold !important;
}

.Admin_black {
  font-family: adminblack !important;
}

.admin_medium {
  font-family: adminmedium !important;
  font-size: 16px;
}

.Admin_std {
  font-family: adminstdbook !important;
}

.adminstd_medium {
  font-family: admin_std_medium !important;
}

.sales {
  font-weight: normal !important;
}

/* In your CSS file or within your Tailwind configuration */
.hover-text-yellow {
  transition: color 0.3s ease;
  /* Smooth transition effect */
}

.hover-text-yellow:hover {
  color: #ffc400;
  /* Yellow color when hovered */
}

.sidebar-menu {
  position: fixed;
  background-color: #253338;
  /* top: 0; */
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  /* width: 16rem; */
  z-index: 101;
  color: #9e9e9e;
}

.header_sticky_main {
  display: inline-block;
  width: 100%;
  margin: auto;
  height: auto;
}

.header_sticky_section {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.boder_bottom_line {
  border-bottom: 3px solid #000;
  background-color: #000;
  margin-bottom: 16px;
}

/* add error msg css */
.input-error {
  color: #fa0000 !important;
  font-size: 16px !important;
  margin: 3px !important;
  padding: 0 !important;
  font-family: CircularRegular;

  /* bottom: -17px;
    height: 15px;
    left: 10px;
    position: absolute; */
}
.Asterisk_error {
  color: #fa0000 !important;
}
.password-show-input {
  position: relative;
}
.Show-password {
  position: absolute;
  right: 0;
  top: 23px;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  color: #818181;
  user-select: none;
  font-family: CircularSTDMedium;
}

/* Media Queries for Responsive Font Sizes */
@media (max-width: 840px) {
  .q_sales_dashbaord {
    display: flex;
    flex-direction: column;
  }
}
.main-content {
  /* overflow-x: hidden;
  overflow-y: auto;
  overflow-x: hidden; */
  min-height: 100vh;
}

/* .main-content {
	overflow-x: auto;
	overflow-y: auto;
	height: 100vh;
	overflow-x: hidden;
	position: relative;
} */

.importBtn {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #0a64f9;
  position: absolute;
  left: -6px;
}

.border-customColor {
  border-color: #438aff;
  /* Replace with your desired border color */
}

.custom-tooltip {
  width: 15rem;
  color: #ffffff;
  padding: 10px;
  transform: translateY(10px);
  background: #253338 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
}
.custom-tooltip.auto {
  width: max-content;
}

.custom-box {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  opacity: 1;
}

.custom-button {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  opacity: 1;
}

.menu-item.active {
  background-color: gray;
  /* Set the default active background color */
}

.menu-item:hover {
  color: #ffc400;
  /* Set the hover color */
}

.vl {
  border-left: 2px solid #0000001f;
  height: 80px;
  margin-top: 13px;
}

select {
  margin-top: -1rem;
}

select::-ms-expand {
  display: none;
}

select option {
  box-sizing: border-box;
  margin-top: -1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@keyframes fadeCalnder {
  0% {
    height: 0px;
    width: 0px;
  }

  30% {
    height: 50px;
    width: 50px;
  }

  30% {
    height: 150px;
    width: 150px;
  }

  50% {
    height: 250px;
    width: 250px;
  }

  70% {
    height: 300px;
    width: 300px;
  }

  85% {
    height: 400px;
    width: 400px;
  }

  100% {
    height: 500px;
    width: 500px;
  }
}

.dataCalender {
  background-color: #fff;
  z-index: 5;
  top: 13.5rem;
  bottom: 3px;
  z-index: 5;
  right: 6.5rem;
  top: 11.5rem;
  bottom: 3px;

  position: absolute;
  /* display: flex; */
  transition:
    width 5s,
    height 4s;
  height: 550px;
  width: 70%;
  padding: 12px;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px;
  opacity: 1;
}

@media only screen and (max-width: 1366px) and (min-width: 768px) {
  .dataCalender {
    background-color: #fff;
    z-index: 5;
    top: 12rem;
    bottom: 3px;
    z-index: 5;
    right: 5.1rem;
    bottom: 3px;
    position: absolute;
    transition:
      width 5s,
      height 4s;
    height: 570px;
    width: 60%;
    padding: 12px;
    box-shadow: 0px 3px 6px #0000001f;
    border-radius: 8px;
    opacity: 1;
  }
}

.nonedatacalender {
  width: 0px;
  height: 0px;
  display: none;
}

.days_btn {
  border-width: 1px;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

ul li .q_filter_data_days {
  display: flex;
  flex-direction: row;
}

.pagination {
  display: flex;
  justify-content: end;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.page-item {
  list-style: none;
  margin: 0 5px;
}

.page-link {
  display: block;
  padding: 8px 15px 8px 15px;
  border: 1px solid #ebebeb;
  text-decoration: none;
  color: #333;
  border-radius: 4px;
  opacity: 1;
}

.page-item.active {
  display: block;
  /* padding: 8px 15px 8px 15px; */
  border: 1px solid #0a64f9;
  text-decoration: none;
  color: #333;
  border-radius: 4px;
  opacity: 1;
}

.page-link:hover {
  background-color: #f0f0f0;
}

.Activeclass {
  background-color: #526167;
  color: #fff;
  border-radius: 4px;
}

.active_link_pagination {
  color: #fff;
}

.select_date_btn {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 10px;
  width: 100%;
}

@media screen and (min-width: 640px) and (max-width: 1080px) {
  .days_filter {
    font-size: 10px !important;
    font-family: "Admin_std", sans-serif;
    /* Assuming 'Admin_std' is the font name */
    font-weight: normal;
  }

  /* CSS rules for screens between 600px and 900px wide */
}

.days_filter {
  font-size: 16px;
  font-family: "Admin_std", sans-serif;
  /* Assuming 'Admin_std' is the font name */
  font-weight: normal;
}

a.days_filter {
  display: flex;
  justify-content: space-between;
}

.main_blocks {
  margin-right: -2.4rem;
  margin-left: -2.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.main_block_content {
  margin-right: -2.2rem;
  margin-left: -2.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.search_btn {
  margin-top: 2rem !important;
}

.custom-text-input {
  width: 100%;
  background-color: #fff;
  color: #000;
  font-size: 15px;
  /* Large screens */
  border: 1px solid #ccc;
  outline: none;
  padding: 8px 16px;
  /* Adjust padding as needed */
  border-radius: 4px;
}

/* Media Queries for Responsive Font Sizes */
@media (max-width: 991px) {
  .custom-text-input {
    font-size: 12px;
    /* Medium screens */
  }
}

@media (max-width: 767px) {
  .custom-text-input {
    font-size: 10px;
    /* Small screens */
  }
}

@media (max-width: 575px) {
  .custom-text-input {
    font-size: 8px;
    /* Extra Small screens */
  }
}

.q-custom-modal-content.modal_custom {
  height: auto !important;
  top: 10%;
}

.input_area label {
  display: block;
  margin-bottom: 10px;
}

.plr0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.datarange_days_order {
  display: flex;
  flex-direction: row;
  color: #666;
  margin-left: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  flex-wrap: wrap;
  font-size: 14px !important;
  font-family: CircularSTDBook !important;
}

.datarange_days_order > * {
  margin-right: 1.5rem;
}

.q-order-daterange-button {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
  outline: none;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.q-order-daterange-button:hover {
  background-color: #0056b3;
}

.q-order-daterange-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.q-order-daterange-button:active {
  transform: scale(0.98);
}

@media (min-width: 640px) {
  .datarange_days_order {
    font-size: 18px;
  }
}

@media (max-width: 639px) {
  .datarange_days_order {
    font-size: 18px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .datarange_days_order {
    font-size: 18px;
  }
}

.order_Details_days {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

/* Custom Styles for Flex Container */
.custom-flex-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

/* Custom Styles for Relative Positioning */
.custom-relative {
  position: relative;
}

/* Custom Styles for Date Picker Labels */
.custom-label {
  margin-bottom: 0.5rem;
  color: #818181;
}

/* Custom Styles for Date Picker Container */
.custom-datepicker-container {
  position: relative;
  width: 100%;
}

/* Custom Styles for Date Picker Input */
.custom-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-top: 0.5rem;
  outline: none;
  width: 100%;
  font-size: 16px;
}

/* Custom Styles for Date Picker Icon */
.custom-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  cursor: pointer;
}

/* Custom Styles for Search Button */
.custom-search-btn {
  margin-top: 1.5rem;
}

/* Custom Styles for Search Button */
.custom-search-button {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

/* Responsive Media Queries */
@media (min-width: 640px) {
  /* Adjust the layout for small screens (sm:flex-row) */
  .custom-flex-container {
    flex-direction: row;
    justify-content: flex-start;
  }

  .custom-datepicker-container {
    width: 100%;
  }

  .custom-search-btn {
    margin-top: 0;
    margin-left: 1rem;
  }
}

@media (min-width: 768px) {
  /* Adjust the layout for medium screens (md:flex-row) */
  .custom-flex-container {
    flex-direction: row;
    justify-content: flex-start;
  }
}

/* Add more media queries as needed for larger screens */

.q_order_page_labal {
  display: block;
  font-size: 16px;
  color: #818181;
  font-family: "CircularSTDBook", sans-serif;
  /* Assuming CircularSTDBook is a font family */
  margin-bottom: 1rem;
}

.q_sales_number {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  font-family: CircularSTDMedium !important;
  position: relative;
  top: 14px;
}

.q_sideMenu_layout_details {
  background-color: #334247;
  /* padding: 1rem; */
  box-shadow: #0000001f;
  width: 100%;
  text-align: center;
  z-index: 1;
  font-size: 15px;
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 2px;
  height: auto;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0a64f9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
}

.arrow-up {
  width: 10px !important;
  height: 10px !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.permiddion_heading {
  width: 100%;
  height: auto;
}

.q_sideMenu_layout_details a:active a:hover {
  background-color: #0a64f9;
  border: 1px solid #000;
}

a.flex.text-center.submenu-item.text-gray-400.py-4.CircularSTDBook:active {
  border: 1px solid #334247;
  background-color: #334247;
  color: #ffc400;
}

a.flex.text-center.submenu-item.text-gray-400.py-4.CircularSTDBook:hover {
  border: 1px solid #334247;
  background-color: #334247;
  color: #ffc400;
}

.submenu-item {
  display: flex;

  background-color: #334247;
  color: #808080;
  /* or any other color you prefer */

  font-family: "CircularSTDBook", sans-serif;
}
.nested-submenu-item {
  display: flex;
  background-color: #334247;
  font-family: "CircularSTDBook", sans-serif;
}

.submenu-item:hover {
  color: #ffc400;
}

.submenu-item.active {
  color: #ffc400;
}

.DropDown-memu.activeTab {
  color: #ffc400;
}

.image-container {
  position: relative;
  display: inline-block;
}

.hoverable-image {
  transition: filter 0.3s ease;
}

.hoverable-image:hover {
  filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(886%)
    hue-rotate(359deg) brightness(102%) contrast(105%);
  /* You can adjust the filter properties based on your desired effect */
}

.hoverable-image:active {
  filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(886%)
    hue-rotate(359deg) brightness(102%) contrast(105%) !important;
  /* You can adjust the filter properties based on your desired effect */
}

.q_date_range_filter_details {
  display: none;
}

@media screen and (max-width: 675px) and (min-width: 210px) {
  .q_date_range_filter_details {
    display: block;
    display: flex;
    justify-content: flex-end;
  }

  .order_Details_days {
    display: none;
  }
}

.q-sales-item {
  width: 40%;
}

.q-sales-in {
  width: 40%;
}

.q-sales-total {
  width: 40%;
}

.inventory_btn {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 53%;
}

.q-add-inventory-section-header {
  display: flex;
  padding: 20px;
}

.q-attributes-top-detail-section li:before {
  color: #000;
  content: "\2022";
  left: 0;
  position: absolute;
  top: 0;
}

.q_product_image_rounded > img {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.q_product_image_rounded {
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
}

.firstVariant_product {
  position: absolute;
  left: 3rem;
  top: 0;
  z-index: 1;
}

.secondVariant_product {
  position: absolute;
  left: 4rem;
  top: 0;
  z-index: 2;
}

.thirdVariant_product {
  position: absolute;
  left: 5rem;
  top: 0;
  z-index: 3;
}

.fourthVariant_product {
  position: absolute;
  left: 6rem;
  top: 0;
  z-index: 4;
}

div.q_label_details::after {
  border: 1px solid #818181;
}

.border_transpert_details > input {
  border-top: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
}

.defaultDrag_div {
  cursor: pointer;
  position: relative;
  height: auto;
  padding: 10px;
}

.error-message {
  color: red;
}

.default-DeleteIcon {
  display: flex;
  justify-content: end;
}

.defaultDrag_div {
  cursor: pointer;
  position: relative;
  height: 159px !important;
  padding: 10px;
}

.default-img {
  width: 100%;
  height: 320px;
  object-fit: contain;
}

.default-img-inputfield {
  display: none;
  width: 100%;
}

.img-DeleteIcon {
  position: absolute;
  top: 7px;
  right: 7px;
}
.img-DeleteIcon img {
  height: 40px;
  width: 40px;
}

.img-DeleteIcon img {
  height: 40px;
  width: 40px;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #f9f9f9 !important;
  color: #0a64f9 !important;
  border: 1px solid #0a64f9 !important;
  padding: 19px 15px;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  padding: 19px 15px !important;
}

.table_status_selected > select {
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  height: auto;
}

/* date range css  */
.react-datepicker-popper {
  z-index: 2 !important;
}

.qvrowmain label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  display: block;
}

.vender_list svg {
  right: 0px !important;
  top: 0px !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.q-attributes-main-page {
  margin: 0px 0px;
}

.store-items-list {
  margin-top: 10px !important;
}

.store-items {
  padding-top: unset !important;
}

.store-item-card {
  display: flex;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px;
  cursor: pointer;
}

.store-item-card-manager {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px;
  padding: 24px 20px;
  margin: 12px 0px;
}

.store-items-address {
  font-style:
    Circular Std Medium,
    Regular !important;
}

.store-items-store-name {
  font-size: 18px !important;
  font-family: admin_std_medium;
}

.store-items-store-name-address {
  display: flex;
  flex-wrap: wrap;
  font-family: adminstdbook;
  color: #7b7b7b;
  font-size: 16px;
}

.store-items-edit-btn {
  font-family: adminstdbook;
  font-size: 14px;
  color: #0a64f9;
  cursor: pointer;
}
.store-items-store-names {
  font-size: 14px;
}

.managerStore-title {
  font-size: 20px;
  font-family: admin_std_medium;
}

.managerStore-title-main {
  margin-left: -15px;
  margin-right: -15px;
}
.managerStore-btn {
  font-size: 18px;
  color: #0a64f9;
}

.Add-manager-forrm-title {
  font-size: 20px;
  font-family: admin_std_medium;
}

.header-menu {
  font-size: clamp(10px, 1.5vw, 18px) !important;
}

.custom-datepicker-remove-border fieldset {
  border-width: 0 !important;
}

.stock-due-date svg.MuiSvgIcon-root,
.issued-date svg.MuiSvgIcon-root {
  position: relative !important;
  right: 0px !important;
  top: 0px !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.stock-due-date .MuiPickersDay-root.MuiPickersDay-today,
.issued-date .MuiPickersDay-root.MuiPickersDay-today {
  color: #0056b3 !important;
  background-color: transparent !important;
}
.excelLoader {
  margin-right: 10px;
}
.DatePicker-coupon > div > fieldset {
  border: 0 !important;
}
.button-load {
  margin: 10px !important;
  border: 1px solid rgba(7, 145, 138, 0.5) !important;
  color: rgba(7, 145, 138) !important;
}
.forgot-password {
  align-self: end !important;
  font-size: 14px !important;
  text-decoration: underline !important;
  color: #0a64f9 !important;
  font-family: QuicksandMedium, "Quicksand" !important;
}
.login-customer-form .sub-heading-from {
  font-family: QuicksandMedium, "Quicksand" !important;
  font-size: 14px;
  color: #232323 !important;
  margin: 10px 0 25px 0;
}

.sub-heading-from {
  width: 300px;
}
.input-field > div > input,
.input-field > label {
  font-family: CircularBold !important;
}

.norecordFound {
  padding-left: 20px !important;
  width: 10% !important;
  margin-left: 20px !important;
  /* height: 10vh !important; */
  vertical-align: cen;
  padding-top: 5px !important;
}
.w-store {
  width: 100px;
}

.error-message-stocktake {
  color: red;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.fixed-bottom {
  position: fixed !important;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding-left: 0rem;
  padding-right: 0rem;
}

.sticky-box {
  background-color: #fff !important;
  width: 100%;
  box-shadow: 0 5px 10px 1px #ddd;
}

@media screen {
  .pagination-div {
    font-family: adminstdbook;
  }
  h1 {
    font-family: CircularMedium !important;
  }

  .box_shadow_div label {
    color: #818181;
    font-size: 14px;
    font-family: CircularSTDBook !important;
    display: block;
  }
  .date-picker-font {
    font-family: CircularSTDBook !important;
  }

  input {
    font-family: CircularSTDBook !important;
  }
  .react-datepicker {
    font-family: CircularSTDBook !important;
  }
  .common-font-bold {
    font-family: CircularSTDBook !important;
  }
}

.custom-input-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 2px;
  border-color: black;
}

.custom-input-root.black-border .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

.orderSummeryPageOrderStatus svg {
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .box_shadow_div {
    box-shadow: 0px 10px 10px -10px #ddd !important;
    background-color: unset !important;
    padding: 0 !important;
  }
  .q-attributes-bottom-header {
    padding: 0 !important;
  }
}

body.main-container-overflow {
  overflow: hidden !important;
}

.no-top-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2) !important; /* Adjust values for side/bottom shadows */
  border-top: none !important; /* Optional: remove any border at the top */
}
