.error_banner_area {
  width: 100%;
  height: 100%;
  background: url("../../Assests/PageNotFoundImages/banner_bg.webp");
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-repeat: no-repeat;
  min-height: calc(100vh - 0px);
  display: flex;
  align-items: center;
}

.error_banner_left {
  width: 20%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: url("../../Assests/PageNotFoundImages/banner_left.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.error_banner_right {
  width: 20%;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: url("../../Assests/PageNotFoundImages/banner_right.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.error_banner_center {
  width: 40%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error_banner_center a {
  color: #0d6efd;
  text-decoration: none;
}

.error_banner_center img {
  width: 40%;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 1280px) {
  .error_banner_left,
  .error_banner_right {
    width: 23%;
  }
}

@media (max-width: 991px) {
  .error_banner_left {
    background-position: right;
  }
  .error_banner_right {
    background-position: left;
  }
  .error_banner_center {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .error_banner_left,
  .error_banner_right {
    width: 36%;
  }
}
