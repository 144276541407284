@font-face {
  font-family: CircularSTDBook;
  src: url("../Assests/Fonts/circulur-std-book.TTF");
}

@font-face {
  font-family: CircularSTDMedium;
  src: url("../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf");
}

@font-face {
  font-family: CircularBold;
  src: url("../Assests/Fonts/circular-bold.TTF");
}

.dropdownBox {
  margin-top: 5px;
  /* margin-bottom: 14px; */
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
}

.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.search-area .toggle-btn {
  width: 5%;
  text-align: right;
}

.search-area .toggle-btn .down-arrow-image {
  display: inline-block;
  width: 30px;
  cursor: pointer;
  margin-bottom: 6px;
}

.search-selected-item {
  display: flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  padding: 5px 0px 5px 0px;
}

.search-selected-item-without-padding {
  display: flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  min-height: 40px;
}

.selected-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.search-selected-item input.search-item,
.search-selected-item-without-padding input.search-item {
  border: none !important;
  border-radius: 0px !important;
  margin-top: 3px;
  /* padding: 0px 0px 0px 5px; */
  margin: 0px;
  width: 270px;
}

.search-selected-item input.search-item::placeholder,
.search-selected-item-without-padding input.search-item::placeholder {
  font-size: 14px !important;
  letter-spacing: normal;
  text-align: left;
  letter-spacing: 0px;
  color: #888888 !important;
  opacity: 1;
  font-family: CircularSTDBook !important;
}

.search-selected-item .search-item:focus,
.search-selected-item-without-padding .search-item:focus {
  border-left: 2px solid #2e5eff !important;
}

.input_area .css-t3ipsp-control {
  padding-right: 30px !important;
}

.input_area .css-1nmdiq5-menu {
  overflow: hidden !important;
}

.selected-item .item {
  background-color: #f2f2f2;
  padding: 7px 11px;
  border-radius: 0px;
  opacity: 1;
  display: flex;
  align-items: center;
}

.selected-item .item span {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 13px;
  font-family: CircularSTDMedium;
  margin-right: 6px;
  text-transform: capitalize;
}

.selected-item .item:last-child {
  margin-right: 8px;
}

.selected-item .item .cancel-image {
  display: inline-block;
  width: 15px;
  height: 15px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 2px;
}

.selected-item .item .cancel-image:hover {
  background-color: #afafaf;
  padding: 2px;
}

/* 
.selected-item span {
  background-color: #e1e1e1;
  padding: 7px;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
} */

.options-box {
  padding: 2px 0px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-height: 160px;
  overflow-x: auto;
}

.options-box .item {
  background: #fff;
  border-radius: 3px;
  border: 1.5px solid #bebebe;
  padding: 4px 10px;
  box-sizing: border-box;
  margin-right: 12px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  margin-bottom: 8px;
  font-family: CircularSTDBook !important;
  text-transform: capitalize;
}

.rotate-dropdpown {
  transform: rotate(180deg);
  transition: 0.3s all linear;
}

.remove-rotate-dropdown {
  transform: rotate(0deg);
  transition: 0.3s all linear;
}

.active-item {
  color: #0a64f9;
  border: 1.5px solid #0a64f9 !important;
}

/* add product upload image */

.image-upload-gallery {
  display: flex;
  flex-direction: row;
}

.image-upload-gallery .image-display {
  border: 1px solid #ececec !important;
  padding: 5px;
  position: relative;
  margin-left: 15px;
}

.image-upload-gallery .image-display:first-child {
  margin-left: 0px;
}

.image-upload-gallery .image-display img {
  width: 190px;
  max-width: 180px;
  height: 170px;
  object-fit: cover;
}

.image-display .delete-image-icon .delete-image {
  background-color: rgba(255, 255, 255, 0.7);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 3px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 4px 4px 0px 0px;
}

.image-upload-gallery .image-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-left: 6px;
}

.quic-btn-bulk-edit {
  border: 1.5px solid #0a64f9;
  border: 2px solid #0a64f9;
  border-radius: 4px;
  opacity: 1;
  background-color: transparent !important;
  color: #0a64f9 !important;
  font-weight: 500 !important;
}

.checkbox-area {
  display: flex;
  align-items: center;
}

.checkbox-area label {
  margin-left: 10px;
  margin-top: 3px;
}

/* varient add section */
.varientAddSection {
  display: flex;
  align-items: center;
}

.custom-dropdown-control .Dropdown-arrow {
  display: none;
}

.loader-box {
  display: grid;
  place-content: center;
}

.loader-box span {
  width: 23px !important;
  height: 25px !important;
  color: #fff;
}

.error-alert,
.error-alert-varient {
  color: #ff0000;
  font-size: 11px;
  font-weight: 400;
}

.error-alert-varient {
  position: absolute;
}

.product-note-section {
  margin: 11px 0px;
}

.product-note-section .product-note {
  margin: 2px 0px;
  font-size: 15px;
  color: #000;
  font-family: CircularSTDBook !important;
}

.varient-edit-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.varient-edit-text .title {
  font-size: 18px !important;
  color: #000;
  font-family: CircularSTDBook !important;
}

/* product page edit modal css */
.product-edit-modal {
  width: 80%;
  margin: 0 auto;
  /* height: 65%; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px;
  opacity: 1;
  /* padding: 20px; */
  box-sizing: border-box;
  overflow-y: auto;
}

.product-edit-modal .css-13xfq8m-MuiTabPanel-root {
  padding: 0px !important;
}

.product-varient-form {
  margin-bottom: 22px;
}

.product-edit-modal .cancel-btn {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  z-index: 99;
}

.bulk-edit-note {
  color: #0a64f9;
  text-align: left;
  font: normal normal normal 15px/21px Circular Std Medium;
  letter-spacing: 0px;
  color: #0a64f9;
}

.bulk-edit-note .note {
  color: #000000;
}

.varient-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-height: 60vh;
  overflow-y: auto;
}

.varient-form .varientName {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: CircularSTDMedium !important;
}

.varient-container {
  width: 49%;
  margin-right: 1%;
}

.varientform .form {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 10px;
}

.varientform .form .inputs {
  margin-right: 10px;
}

.varient-input-wrapper label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
}

.po-description-area {
  width: 100%;
}

.po-description-area .input_area input {
  height: 70px;
}

.edit-profile-btns {
  margin-top: 10px;
}

.edit-profile-btns button {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 3px;
  opacity: 1;
  padding: 10px 60px;
  box-sizing: border-box;
  margin-right: 10px;
  box-sizing: border-box;
  border: 2px solid #000;
  border-radius: 5px;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 30px;
  text-transform: capitalize;
  color: #000 !important;
}

.edit-profile-btns button.edit {
  background-color: #000 !important;
  color: #fff !important;
}

.product-edit-modal .tab-list {
  padding: 15px 15px 0px 15px;
  box-sizing: border-box;
}

.tab-list button {
  letter-spacing: normal;
  color: #6a6a6a;
  font-weight: 400;
  font-family: CircularSTDBook !important;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 16px;
  text-transform: capitalize;
}

.tab-list button.Mui-selected {
  border: 0px solid transparent;
  background: #ebf2ff;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  color: #0a64f9;
  font-weight: 400;
}

.tab-list .MuiTabs-indicator {
  display: none !important;
}

/* sales history page css */

.sales-history-table thead {
  background-color: #253338;
}

.sales-history-table thead th {
  color: #fff;
  padding: 10px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 15px/21px Circular Std Book;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: left;
}

.sales-history-table tbody tr th,
.sales-history-table tbody tr td {
  /* font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-circular-std-medium);
  letter-spacing: var(--unnamed-character-spacing-0); */
  /* color: var(--unnamed-color-000000); */
  text-align: left;
  /* font: normal normal normal 16px/22px Circular Std Medium; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: 600;
}

.search-history-box {
  margin-bottom: 20px;
  text-align: right;
}

.search-history-box input {
  border: 1px solid #000;
  border-radius: 2px;
  padding: 4px 8px;
  box-sizing: border-box;
}

/* bulk vendor edit modal css */

.vendor-add-input {
  width: 100%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 0px 20px !important;
}

.vendor-add-input .dropdownBox {
  width: 82%;
  margin-top: 0px;
  overflow: hidden;
}

.vendor-add-input .quic-bulk-vendor-edit {
  width: 15%;
  background: #0a64f9 0% 0% no-repeat padding-box;
  background: #0a64f9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #fff;
  padding: 8px;
  box-sizing: border-box;
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.vendor-add-input button {
}

.bulkvendor-table-container {
  box-shadow: none;
}

.bulkvendor-table-container .vendor-cost-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  box-sizing: border-box;
}

.vendor-add-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.vendor-add-loading span {
  color: #fff !important;
  width: 20px !important;
  height: 20px !important;
}

.no-vendor-text {
  padding: 10px;
  box-sizing: border-box;
}

.bulk-varient-edit-form {
  padding: 20px !important;
}

.bulk-varient-edit-form .q-add-categories-section-middle-footer {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 20px 30px;
  box-sizing: border-box;
}

.bulk-varient-edit-form label {
  letter-spacing: normal;
  text-align: left;
  font-family: CircularSTDBook !important;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #818181;
  opacity: 1;
}

.bulk-varient-edit-form input {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  opacity: 1;
}

.bulk-vendor-table {
}

/* alert toast css */
.toast-modal {
  position: absolute;
  bottom: 120px;
  right: 0;
  background: red;
  padding: 0px;
  border-radius: 5px;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.toast-modal button {
  font-size: 20px;
}

/* loader css */
.loading-box {
  width: 100%;
  height: calc(100vh - 76px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* product data table */

.product-table-data {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-table-data p {
  width: 14.28%;
}

.product-table-data div {
  width: 14.28%;
}

.product-data-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-data-table-header p {
  width: 14.28% !important;
}

.all-product-list {
  font-family: CircularSTDMedium !important;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;
}

/* CkEditor styling */

.ck-editor__editable {
  min-height: 150px;
  /* Set your desired height */
  margin-bottom: 8px;
  padding-left: 18px !important;
}

/* all css start from here... */

.product-input-title {
  letter-spacing: normal;
  text-align: left;
  letter-spacing: 0px;
  color: #6a6a6a !important;
  font-size: 14px;
  opacity: 1;
  font-family: CircularSTDBook !important;
  /* margin-bottom: 7px !important; */
}

.image-list h1 {
  letter-spacing: normal;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: CircularBold !important;
  margin-bottom: 7px;
  line-height: normal;
  margin-top: 12px;
}

.image-list .image-title-show {
  text-align: left;
  letter-spacing: 0px;
  color: #6a6a6a;
  opacity: 1;
  font-size: 12px !important;
  font-family: CircularSTDBook !important;
}

.varient-block .multiple-items {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 5px;
}

.varient-block .multiple-items span {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-family: CircularBold !important;
  margin-right: 18px;
}

.checkbox-input {
  color: #0a64f9;
  border-radius: 4px;
  opacity: 1;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.varient-block .checkbox-area .check-text {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  margin-left: 8px;
  font-size: 16px;
}

.varient-select-section {
}

.varient-select-section h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  font-family: CircularBold;
}

.varientAddSection .input_area .css-13cymwt-control,
.varientAddSection .input_area .css-t3ipsp-control,
.varientAddSection .input_area .css-16xfy0z-control {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  opacity: 1;
  padding: 1px 0px;
  box-sizing: border-box;
}

.input_area .css-t3ipsp-control {
  padding-right: 0px !important;
}

.varientAddSection .input_area .css-1xc3v61-indicatorContainer {
  /* padding: 0px !important; */
}

.varient-box {
  justify-content: start !important;
}

.single-varient-form {
  min-height: 564px !important;
  display: flex;
  align-items: center;
}

.mass-inventory-modal .cancel-btn {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.submit-btn-click {
  display: flex;
  align-items: center;
  position: relative;
}

.submit-btn-click .loader-box span {
  color: #fff;
  position: absolute;
  left: 11%;
  width: 19px !important;
  height: 19px !important;
  top: 30%;
}

.varientAddSection .dropdown .css-qbdosj-Input {
  /* UI Properties */
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  opacity: 1;
  font-family: CircularSTDBook !important;
}

.varient-attribute-btn {
  border: 1.5px solid #0a64f9;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: normal;
  color: #0a64f9;
  text-align: left;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 15px;
  font-family: CircularSTDBook !important;
  cursor: pointer;
  padding: 12px 55px;
  box-sizing: border-box;
  margin-top: 10px;
  transition: 0.2s all linear;
  width: 100%;
  text-align: center;
}

/* .varient-attribute-btn:hover {
  border: 2px solid #0a64f9;
} */

.varient-generateUpc-row {
  background: #f8f8f8;
  opacity: 1;
  padding: 18px !important;
  margin-bottom: 22px;
  margin-top: 20px;
}

.varient-generateUpc-row .variant-title {
  letter-spacing: normal;
  text-align: left;
  font-family: CircularSTDMedium !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
  font-size: 22px;
}

.varient-generateUpc-row .generateUpc {
  letter-spacing: normal;
  text-align: left;
  font-family: CircularSTDMedium !important;
  letter-spacing: 0px;
  color: #0a64f9;
  opacity: 1;
  font-weight: 400;
  font-size: 18px;
}

.varient-title-name {
  letter-spacing: normal;
  text-align: left;
  font-family: CircularSTDMedium !important;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  font-weight: 400;
  font-size: 18px;
}

.varient-form label {
  letter-spacing: normal;
  text-align: left;
  font-family: CircularSTDBook !important;
  letter-spacing: 0px;
  font-weight: 400;
  color: #6a6a6a !important;
  font-size: 14px !important;
  opacity: 1;
}

.varient-form input {
  background: #ffffff;
  border: 1px solid #dfdfdf !important;
  border-radius: 3px !important;
  opacity: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-family: CircularSTDMedium !important;
}

.check-box-area {
  margin-top: 20px;
}

.create-item-box {
  background-color: #eaf9ff;
  padding: 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 2px;
}

.max-item-error {
  color: red;
  font-size: 14px;
  font-family: CircularSTDMedium !important;
}

.create-item-text {
  color: #000;
  font-size: 16px;
  font-family: CircularSTDMedium !important;
}

.inventory-input-area {
  width: 100%;
  display: block;
}

.inventory-input-area input {
  width: 40%;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 7px;
  box-sizing: border-box;
  outline: 0;
  text-transform: capitalize;
}

.inventory-input-area input:nth-child(n + 1) {
  margin-top: 10px;
}

.Sticky-multiple-delete {
  position: sticky;
  bottom: 0;
  z-index: 999;
  /* margin-bottom: 20px; */
  background: #fff;
  box-shadow: 0 5px 10px 1px #ddd !important;
  margin: 0 auto;
  text-align: center;
}

.Sticky-multiple-delete button {
  font-family: CircularSTDBook !important;
  font-size: 16px;
  border: 2px solid #ff0000 !important;
  color: #ff0000 !important;
  width: 40%;
  margin: 20px;
  background-color: #fff;
}

.product-layout {
  position: relative;
}

.delete-inventory-text {
  font-size: 16px;
  color: #ccc;
}

.product-error-note {
  color: #000;
  font-size: 12px;
  font-family: CircularSTDBook;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 7px;
  border: 1px solid #0a64f9;
  padding: 5px 9px;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  cursor: default;
}

.product-error-note::before {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  border-bottom: 1px solid #0a64f9;
  border-right: 1px solid #0a64f9;
  left: -6px;
  transform: rotate(-224deg);
  background-color: #fff;
}

.product-error-note svg {
  color: #c1c1c1;
  font-size: 21px;
  cursor: pointer;
}

.product-title .product-heading-title span {
  width: 150px;
}

.product-line-highlight-bg {
  background-color: rgb(208 225 255) !important;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.checkbox-for-all-form .modal-title-tab .MuiTabs-scroller {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  box-sizing: border-box;
}

.checkbox-for-all-form .modal-title-tab .MuiTabs-scroller button {
  color: #0a64f9;
  font-size: 19px;
  text-transform: capitalize;
  font-weight: 600;
  font-family: CircularSTDBook;
  cursor: default;
}

.checkbox-for-all-form .modal-title-tab .MuiTabs-scroller button span {
  display: none;
}

.checkbox-for-all-form .modal-title-tab .MuiTabs-scroller .MuiTabs-indicator {
  display: none;
}
.checkbox-for-all-form .checkbox-item-options {
  box-sizing: border-box;
}
.checkbox-for-all-form .checkbox-item-options .options {
  display: flex;
  flex-wrap: wrap;
  margin-left: 3%;
}

.checkbox-for-all-form .checkbox-item-options .options .label {
  width: 47%;
  margin-bottom: 30px;
  justify-content: unset !important;
  font-size: 18px !important;
}

.checkbox-for-all-form .checkbox-item-options .options .label label {
  display: unset;
}

.item-edit-modal {
  width: 60%;
  background-color: #fff;
  position: relative;
  border-radius: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item-edit-modal .cancel-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.check-uncheck-btn-section {
  display: flex;
  justify-content: end;
  gap: 9px;
}

.bulk-edit-item-btn.disabled {
  background-color: #878787 !important;
  opacity: 0.7;
}

.bulk-edit-item-btn.normal {
  background-color: #0a64f9 !important;
}

.bulk-edit-item-btn.normal-uncheck {
  background-color: #878787 !important;
}
