.alertModal {
  width: 500px;
  background: #ffffff;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 auto;
  padding-top: 22px;
}

.alertModal button {
  margin-top: 20px;
  background-color: #2776ff;
  color: #fff;
  font-weight: 600;
}

.alertModal button:hover {
  background-color: #2776ff;
}


/* for Duplicate pages start  */
.captcha_wrapper{
  padding: 1rem 2rem;
}
canvas { 
  border: 2px solid #0a64f9; 
  border-radius: 4px; 
  cursor: pointer;
} 
.captue_Img_Reload{
  display: flex;
}
#reload-button{
 padding: 1rem;
}
/* for Duplicate pages End */
