.importdata_section{
    color: #818181;
    list-style-type: none;
    font-family: CircularSTDBook !important;
    font-size: 14px;
    position: relative;}




.q-import-main-page {
    margin: 0px 50px;
}


.giftCard_numberExport{
    float: left;
    font-family: CircularSTDMedium !important;
    font-size: 18px;
}
.GiftCardExport{
    float: right;
}
.newHeaderGift{
    float: left;
    padding: 20px;
}