.pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #4caf50;
    color: white;
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px; /* Adjust the margin as needed */
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #4caf50;
    color: white;
  }
  
  .pagination button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  

  .q-add-categories-single-input {
    display: flex;
    flex-direction: column;
}
  .EditEmployeeModal:focus-visible{
    outline: unset !important;
  }