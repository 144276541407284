:root {
    --blue: #0A64F9;
    --background: #EBEBEB;
    --borderColor: #EBEBEB;
    --tableHeaderBG: #253338;
    --white: #f5f5f5;
    --black:#000000;
  }
.dataTable{
    width: 100% !important;
    font-family: CircularSTDBook!important;
}
.dataTables_info{
    padding-left: 1rem;
    /* color: var(--black) !important; */
    font-family: CircularSTDBook !important;
    color:#0A64F9 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    background: var(--background) !important ;
}
.dataTables_wrapper{
      /* padding: 10px 9px; */
    margin-bottom: 1rem;
}
.dataTables_length{
    padding: 1rem;
}
.dataTables_wrapper .dataTables_paginate {
    padding-right: 1rem;
}
.dataTables_length label{
    color: var(--black) !important;
}
table.dataTable>thead>tr>th, table.dataTable>thead>tr>td {
      padding: 10px 26px;
    border-bottom: none;
}
table.dataTable thead th, table.dataTable tfoot th {
    font-weight: normal;
}
table.dataTable>thead>tr>th {
    background: var(--tableHeaderBG) !important;
    color: #FFFFFF;
}
table.dataTable.no-footer {
    border-bottom: none;
}
table.dataTable tbody td {
    padding: 10px 26px;
    border-bottom: 1px solid var(--borderColor);
    width:18% ;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    margin-left: 10px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: var(--blue) !important;
    background: var(--white) !important ;
    border: 1px solid var(--blue) !important;
}


.dataTables_wrapper .dataTables_paginate .paginate_button, .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: var(--white) !important;
    color: #646464!important;
    border: 1px solid var(--borderColor) !important;
    border-radius: 4px;
}
.dataTables_wrapper .dataTables_length select {
    border: 1px solid var(--borderColor) !important;
    border-radius: 3px;    
    background-color: var(--white);
    padding: 8px 30px 8px 8px;    
    color: #646464;    
    margin: 0px 6px 0px;
    outline: none;
    cursor: pointer;
} 
#loyaltyProgramTable_previous,#loyaltyProgramTable_next{
    transform: translate(0px, 14px);
}
.dataTables_wrapper .dataTables_filter input {
    outline: none;
}
#loyaltyProgramTable_filter input{
    transform: translate(0rem, 1rem);
    width: 20rem;
    padding: 0.5rem 1rem;
    color: #000000;
    border: 1px solid #E1E1E1;
}

/* Hide the default dropdown arrow */

.custom-selecttable>select{
     width: 200px !important;
}

.view_details{

    color: #0A64F9;
}

.view_details_order {
    color: #0A64F9;
    font-family: CircularSTDMedium !important;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline; /* Adds an underline to the text */
    text-decoration-color: #0A64F9; /* Sets the color of the underline */
    text-underline-offset: 2px; /* Optional: Adjusts the distance between text and underline */
}

/* .order_status_details_quivkwee{
    color:red
} */

#storeOrderTable_previous,#storeOrderTable_next{
    transform: translate(0px, 7px);
}
.dataTables_paginate a{
    transform: translate(0px, -7px);
}

/* for new table css start  */
.table_custome{
    font-family: CircularSTDBook !important;
}
.table__header{
    background: #253338;
    color: #FFFFFF;
    font-size: 15px;
    font-family: CircularSTDBook !important;
}
.table__header th,.table__body td{
    padding: 6px 12px;
}
.table__body{
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
}

.trBG_ColorSaleSummary{
    background-color: #F8F8F8 !important;
}
.BORHeaderRight{
    border-right: 1px solid #444E51 !important;
}
.BORBodyRight{
    /* border-right: 1px solid #E8E8E8 !important; */
    border-right: 1px solid #cdcdcd !important;
}
.BORREMOVE{
    border-right: none !important;
}

.totalAmountCollected th{
    background-color: #0A64F9 !important;
}
.BORHeaderLeft{
    border-left: 1px solid #444E51 !important;
}
.BORBodyLeft{
    /* border-right: 1px solid #E8E8E8 !important; */
    border-left: 1px solid #cdcdcd !important;
}
.totalBORDERDOWN{
    border-bottom:  1px solid #000000 !important;
}

.Graph-dropdown-content{
    position: absolute;
    right: 2rem;
    background-color: #fff;
    box-shadow: 0 0px 7px rgb(225 225 225);
    border: 1px solid #ddd;
    z-index: 2;
    font-size: 12px;
    cursor: pointer;
    color: #707070;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 0.5rem;
}
/* for new table css End */



.trBG_ColorSaleSummary{
    background-color: #F8F8F8 !important;
}
.BORHeaderRight{
    border-right: 1px solid #444E51 !important;
}
.BORBodyRight{
    /* border-right: 1px solid #E8E8E8 !important; */
    border-right: 1px solid #cdcdcd !important;
}
.BORREMOVE{
    border-right: none !important;
}

.totalAmountCollected th{
    background-color: #0A64F9 !important;
}


.TaxesTable .TaxesTableHead{
    background-color: #FFC400 !important;
    color: #000000 !important;
}
.totalBORDERUPDOWN{
    border-bottom:  1px solid #000000 !important;
    border-top:  1px solid #000000 !important;
}
.totalBORDERDOWN{
    border-bottom:  1px solid #000000 !important;
}


.BORDERDOWNTableCell{
    border-bottom:  1px solid #cbcbcb !important;
}