@font-face {
  font-family: CircularBold;
  src: url("../Assests/Fonts/circular-bold.TTF");
}

@font-face {
  font-family: CircularMedium;
  src: url("../Assests/Fonts/circulur-medium.TTF");
}

@font-face {
  font-family: CircularSTDBlack;
  src: url("../Assests/Fonts/circulur-std-black.TTF");
}

@font-face {
  font-family: CircularSTDBook;
  src: url("../Assests/Fonts/circulur-std-book.TTF");
}

@font-face {
  font-family: CircularSTDMedium;
  src: url("../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf");
}

.q_dateRange_header {
  margin-top: 2rem;
}

.q_header_order_tab {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px !important;
}

.q-datarange-bottom-detail-section {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px !important;
}
.q-order-top-detail-section {
  padding: 10px 20px;

  border-radius: 8px;
  margin: 30px 0;
}

.q-category-top-detail-section li {
  color: #818181;
  list-style-type: none;
  font-family: CircularSTDBook !important;
  font-size: 14px;
  position: relative;
  padding-left: 20px;
}

.q-category-top-detail-section li::before {
  content: "\2022";
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
}

.q-mainorder-bottom-detail-section {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px !important;
}

.q-category-bottom-header-sticky {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 8px;
}

.q-category-bottom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.q-category-bottom-header span {
  color: #000000;
  font-size: 18px;
  font-family: CircularSTDMedium !important;
}

.q-category-bottom-header p {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a64f9;
  font-family: CircularSTDMedium !important;
  font-size: 16px;
}

.q-category-bottom-header p img {
  margin-left: 8px;
}

.q-category-bottom-categories-header {
  background: #253338;
  display: flex;
  padding: 10px 20px;
}

.q-category-bottom-categories-header p {
  color: #ffffff;
  font-size: 15px;
  font-family: CircularSTDBook !important;
  width: 30%;
}

.product-table-sort {
  width: 10%;
}

.product-table-items {
  width: 20%;
  height: 0;
}
.product-table-title {
  width: 20%;
}
.product-table-btn {
  width: 40%;
  display: flex;
  justify-content: end;
}
.product-table-enable-disable {
  width: 30%;
}

.q-category-bottom-categories-listing {
  width: 100%;
  background: #fff;
  border-radius: 8px;
}

.q-category-bottom-categories-single-category {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
  /* cursor: all-scroll; */
  user-select: none;
}
.q-category-bottom-categories-single-category-table {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
  /* cursor: all-scroll; */
  user-select: none;
}
.q-category-bottom-categories-single-category > .categories-title {
  font-size: 16px;
  color: #000000;
  font-family: CircularSTDMedium !important;
  text-transform: capitalize;
  width: 40%;
}

.q-category-bottom-categories-single-category > .categories-items {
  color: #0a64f9;
  /* cursor: pointer; */
  font-family: CircularSTDMedium !important;
  font-size: 16px;
  text-decoration: underline;
}

.q-category-bottom-categories-single-category > .categories-enable-disable {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.category-checkmark-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 200px; */
}

.category-checkmark-div .category-checkmark-label {
  color: #000000;
  font-family: CircularSTDMedium !important;
  font-size: 16px;
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.category-checkmark-div .category-checkmark-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.category-checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.category-checkmark-div
  .category-checkmark-label
  input:checked
  .category-checkmark {
  background-color: #1ec26b;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.category-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.category-checkmark-div
  .category-checkmark-label
  input:checked
  ~ .category-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.category-checkmark-div .category-checkmark-label .category-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.categories-enable-disable > img {
  cursor: pointer;
}

.q-add-categories-section {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px;
}

.q-add-categories-section-header {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.q-add-categories-section-header > span,
.q-add-categories-section-header > a {
  display: flex;
  width: auto;
  /* justify-content: space-between; */
  cursor: pointer;
  color: #000000;
  font-size: 18px;
  font-family: CircularSTDMedium !important;
}

.q-add-categories-section-middle-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.q-add-categories-section-middle-form > .q-add-categories-single-input {
  display: flex;
  flex-direction: column;
}

.q-add-categories-single-input > label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
}

.q-add-categories-single-input input,
.q-add-categories-single-input textarea {
  font-family: CircularSTDBook !important;
  color: #000000;
  font-size: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin: 5px 0 15px 0;
}

.q-add-categories-section-middle-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px 20px 20px;
}

.q-add-categories-section-middle-footer .quic-btn {
  font-family: CircularSTDBook !important;
  font-size: 16px;
  color: #fff;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}

.q-add-dashboard-section-middle-footer .quic-btn-save {
  background: #0a64f9;
}

.q-add-dashboard-section-middle-footer .quic-cal-cancle {
  background: #878787;
  margin-left: 10px;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
}

.view-category-item-modal {
  background: #ffffff;
  box-shadow: 0px 3px 28px #00000014;
  border-radius: 8px;
  outline: none;
}

.q_order_content_Details {
  font-size: 18px;
  opacity: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #818181;
  display: flex;
}

/* add by priya*/

.q_datafilter_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  margin-bottom: -2rem;
}

.q_daterange_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
  padding: 1.5rem 2rem;
}

@media (min-width: 640px) {
  .q_daterange_details {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.custom-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

/* .q_input_details:focus {
  border-color: #4299e1;
  border: 2px solid #4299e1;
} */
.q_input_details:hover{
  border: black;
  border: 1px solid black;
}
.q_input_details:focus {
  border-color: black;
  border: 1px solid black;
  outline: auto;
}

/* .q_date_range_start{
color: #818181;
margin-bottom: 3px;
    font-family: CircularSTDBook !important;

  } */

h3.popup_msg {
  padding: 30px;
  text-align: center;
  font-family: CircularSTDMedium !important;
}

.import_container {
  height: calc(50% - -10px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #bfbfbf;
  background-color: white;
  border-radius: 8px;
  margin-top: 1rem;
  cursor: pointer;
}

.product_table_image_msg {
  position: relative;
  font-size: 12px;
  left: 8rem;
  bottom: 3.5rem;
  color: #ffffff;
  font-family: CircularSTDBook !important;
}

.product_table_image {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  border-radius: 50%;
  border-width: 2px;
  border-color: white;
}

.product_img_section {
  font-size: 12px;
  position: relative;
  left: 4rem;
  bottom: 47px;
  z-index: 1;
  color: #000000;
  width: 48px;
  height: 48px;
  background-color: #0000008f;
  border-radius: 50%;
}

.textMsg {
  position: relative;
  top: 6px;
  left: 8px;
  font-family: CircularSTDMedium !important;
  font-size: 10px;
  color: white;
}

.q-add-dashboard-section-middle-footer {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  padding: 20px;
}

.title_attributes_section label {
  color: #818181;
  font-size: 14px;
  font-family: CircularSTDBook !important;
  display: block;
  margin-top: 1.2rem;
}

.default-Edit-Delete {
  display: flex;
  justify-content: end;
  margin-right: 0.5rem;
}

.default-Edit-Delete .edit{
  margin-right: 2rem;
}

.delete-Box-modal .info_alertBTN{
  /* width: inherit;
  display: flex;
  justify-content: flex-end; */
  margin-top: 2rem;
}