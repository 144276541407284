@font-face {
    font-family: CircularBold;
    src: url("../Assests/Fonts/circular-bold.TTF");
}

@font-face {
    font-family: CircularMedium;
    src: url("../Assests/Fonts/circulur-medium.TTF");
}

@font-face {
    font-family: CircularSTDBlack;
    src: url("../Assests/Fonts/circulur-std-black.TTF");
}

@font-face {
    font-family: CircularSTDBook;
    src: url("../Assests/Fonts/circulur-std-book.TTF");
}

@font-face {
    font-family: CircularSTDMedium;
    src: url("../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf");
}









.q-attributes-top-detail-section {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001F;
    border-radius: 8px;
    margin: 30px 0;
}

.q-attributes-top-detail-section li {
    color: #818181;
    list-style-type: none;
    font-family: CircularSTDBook !important;
    font-size: 14px;
    position: relative;
    padding-left: 20px;
}

.q-attributes-top-detail-section li::before {
    content: '\2022';
    color: #000;
    position: absolute;
    left: 0;
    top: 0;
}


.q-attributes-bottom-header-sticky {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 8px;
}

.q-attributes-bottom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.q-attributes-bottom-header span {
    color: #000000;
    font-size: 18px;
    font-family: CircularSTDMedium !important;
}

.q-attributes-bottom-header p {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0A64F9;
    font-family: CircularSTDMedium !important;
    font-size: 16px;
}

.q-attributes-bottom-header p img {
    margin-left: 8px;
}

.q-attributes-bottom-attriButes-header {
    background: #253338;
    display: flex;
    padding: 10px 20px;
}

.q-attributes-bottom-attriButes-header p {
    color: #FFFFFF;
    font-size: 15px;
    font-family: CircularSTDBook !important;

}

.table_view_sort {
    width: 20%;
}

.table_user_details {
    font-size: 18px;
    color: #000000;
    font-family: CircularBold !important;

}

.table_Existing_customer {
    font-size: 14px;
    color: #0A64F9;
    font-family: CircularSTDMedium !important;
}

.table_phone_details {
    color: #818181;
    font-size: 15px;
    font-family: CircularSTDBook;
    margin-top: 2%;
}

.table_email_details {
    color: #818181;
    font-size: 15px;
    font-family: CircularSTDBook;
    margin-top: 2%;
}

.table_order_details {
    font-size: 18px;
    font-family: CircularSTDMedium !important;
    color: #000000;

}

.table_order_delivery {
    font-size: 16px;
    font-family: CircularSTDBook;
    color: #818181;
    margin-top: 2%;

}

.table_Amount_details {
    font-size: 18px;
    font-family: CircularSTDMedium !important;
    color: #000000;
    margin-top: 2%;


}

.table_amount_status {
    font-size: 17px;
    font-family: CircularSTDBook;
    color: #FF3939;
    margin-top: 2%;

}

.table_order_datedetails {
    font-size: 16px;
    font-family: CircularSTDBook;
    color: #818181;

}

.table_view_details {
    color: #0A64F9;
    /* cursor: pointer; */
    font-family: CircularSTDMedium !important;
    font-size: 16px;
    text-decoration: underline;
}

.table_view_title {
    width: 20%;

}

.table_view_amount {
    width: 20%;
}

.table_view_items {
    width: 20%
}

/* .table_status_selected {
    background: #FFFFFF;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    opacity: 1;
    
    padding: 10px 25px 10px 40px;
    margin-top: 2%;

} */


.attriButes-enable-disable {
    width: calc(100% - 36%);
}


.q-attributes-bottom-attriButes-listing {
    width: 100%;
    background: #fff;
    border-radius: 8px;
}

.q-attributes-bottom-attriButes-single-attributes {
    display: flex;
    /* padding: 20px; */
    border-bottom: 1px solid #E8E8E8;
    /* cursor: all-scroll; */
    user-select: none;
}

.q-attributes-bottom-attriButes-single-attributes>.attriButes-title {
    font-size: 16px;
    color: #000000;
    font-family: CircularSTDMedium !important;
}

.q-attributes-bottom-attriButes-single-attributes>.attriButes-items {
    color: #0A64F9;
    /* cursor: pointer; */
    font-family: CircularSTDMedium !important;
    font-size: 16px;
    text-decoration: underline;
}

.q-attributes-bottom-attriButes-single-attributes>.attriButes-enable-disable {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.attributes-checkmark-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
}

.attributes-checkmark-div .attributes-checkmark-label {
    color: #000000;
    font-family: CircularSTDMedium !important;
    font-size: 16px;
    display: block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.attributes-checkmark-div .attributes-checkmark-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.attributes-checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
}


/* When the checkbox is checked, add a blue background */
.attributes-checkmark-div .attributes-checkmark-label input:checked~.attributes-checkmark {
    background-color: #1EC26B;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.attributes-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.attributes-checkmark-div .attributes-checkmark-label input:checked~.attributes-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.attributes-checkmark-div .attributes-checkmark-label .attributes-checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.attriButes-enable-disable>img {
    cursor: pointer;
}

.q-add-attriButes-section {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001F;
    border-radius: 8px;

}

.q-add-attriButes-section-header {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #E8E8E8;
}

.q-add-attriButes-section-header>span {
    display: flex;
    width: 190px;
    justify-content: space-between;
    cursor: pointer;
    color: #000000;
    font-size: 18px;
    font-family: CircularSTDMedium !important;

}

.q-add-attriButes-section-middle-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.q-add-attriButes-section-middle-form>.q-add-attriButes-single-input {
    display: flex;
    flex-direction: column;
}

.q-add-attriButes-single-input>label {
    color: #818181;
    font-size: 14px;
    font-family: CircularSTDBook !important;
}

.q-add-attriButes-single-input input,
.q-add-attriButes-single-input textarea {
    font-family: CircularSTDBook !important;
    color: #000000;
    font-size: 15px;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    padding: 8px;
    margin: 5px 0 15px 0;

}

.q-add-attriButes-section-middle-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}

.q-add-attriButes-section-middle-footer .quic-btn {
    font-family: CircularSTDBook !important;
    font-size: 16px;
    color: #fff;
    padding: 10px 40px;
    border-radius: 4px;
    cursor: pointer;
}

.q-add-attriButes-section-middle-footer .quic-btn-save {
    background: #0A64F9;
}

.q-add-attriButes-section-middle-footer .quic-btn-cancle {
    background: #878787;
    margin-left: 10px;
}


.q_pagination_entry_section {

    display: flex;
    justify-content: end;

}
.custom-text-input {
    width: 100%;
    background-color: #fff;
    color: #000;
    font-size: 15px; 
    border: 1px solid #ccc;
    outline: none;
    padding: 8px 16px; 
    border-radius: 4px;
    -moz-appearance:none; 
-webkit-appearance:none; 
appearance:none;
  }
  
  /* Media Queries for Responsive Font Sizes */
  @media (max-width: 991px) {
    .custom-text-input {
      font-size: 12px; /* Medium screens */
    }
  }
  
  @media (max-width: 767px) {
    .custom-text-input {
      font-size: 10px; /* Small screens */
    }
  }
  
  @media (max-width: 575px) {
    .custom-text-input {
      font-size: 8px; /* Extra Small screens */
    }
  }

  /* Styles for the container flex div */
.q-order-page-container {
    display: flex;
    flex-direction: column;
    @media (min-width: 640px) {
      flex-direction: row;
    }
  }
  
  /* Styles for each filter container */
  .q-order-page-filter {
    flex: 1;
    margin-bottom: 2rem;
    @media (min-width: 640px) {
      margin-right: 2rem;
    }
  }

  
  /* Styles for the label */
  .q-order-page-label {
    display: block;
    margin-bottom: 1rem;
    color:#818181;
    font-family: CircularSTDBook;
  }
  
  /* Styles for the select dropdown */
  .custom-text-input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Styles for the dropdown arrow */
  .q-order-page-dropdown-arrow {
    font-size: 1.5rem;
    position: relative;
    right: 1rem;
    display: flex;
    flex-flow: row-reverse;
    top: -2.5rem;
  }
  
  /* Optional: Hide the default arrow on the select dropdown */
  .custom-text-input::-ms-expand {
    display: none;
  }
  

  .q_order_border{
    border: 1px solid #EBEBEB;
    padding: 5px 8px 5px 8px;
    margin: 0px 8px 8px 8px;
    border-radius: 4px;
    color:black !important

  }

  
